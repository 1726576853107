<p class="my-11 flex justify-center text-3xl font-medium">Privacy Notice</p>
<div class="container mx-auto">
  <p class="text-base">1. PURPOSE OF THIS DOCUMENT</p>
  <p>
    1.1 This notice (Privacy Notice) applies to information Bite Investments or any of its
    subsidiaries, affiliates or entities ‘sponsored' such as investment funds and partnerships
    (collectively referred to as the Bite) holds about you as an investor or user of its technology
    , whether you are a past, present or prospective investor, as well as its officers.<br />

    1.2 This Privacy Notice also covers Bite's website [http://biteinvestments.com] (Website) and
    any personal information we collect from you when you use it. Please note that the Website is
    hosted in Singapore by Microsoft Azure. Some of the links on the Website may lead to third party
    websites with their own privacy notices, which may be different to this Privacy Notice, and you
    should read those notices carefully. 1.3 If you are a tenant in a property held through a Bite
    fund or investment opportunity (Bite Products) or an employee or officer of any business which
    Bite or of any business associated with Bite has invested in, separate privacy notices will
    apply. 1.4 Wherever we have said “we”, “our” or “us”, we mean Bite. “You” or “yours” refers to
    you as a past, present or prospective investor, an officer of Bite or Bite products, or a user
    of the Website. 1.5 This Privacy Notice explains what information Bite collects about you and
    individuals connected to your business, how we'll use that information, who we'll share it with,
    the circumstances when we'll share it, and what steps we'll take to make sure it stays private
    and secure. It continues to apply even if your relationship with us ends. It should also be read
    alongside your other documentation you have with Bite products and the associated Funds (such as
    contracts, terms and conditions, subscription agreements and the like) as these may include
    sections relating to the use and disclosure of information. Where there is any conflict between
    the terms of this Privacy Notice and any other document in relation to data protection the terms
    of this Privacy Notice shall prevail, although its contents are not contractual. 1.6 An
    “individual connected to your business” could be any guarantor, a director, officer or employee
    of a company, partners or members of a partnership, any substantial owner, controlling person,
    or beneficial owner, trustee, settlor or protector of a trust, account holder of a designated
    account, recipient of a designated payment, your attorney or representative (e.g. authorised
    signatories), agent or nominee, or any other persons or entities with whom you have a
    relationship that is relevant to your relationship with us. Whenever we say “you”, “individuals
    connected to your business” should be read as included as well. 1.7 For the purpose of this
    Privacy Notice, Bite will act as data controller in accordance with the Cayman Islands Data
    Protection Law (as amended from time to time) (Law). Bite may also process personal data from
    other entities of the group from time to time. 1.8 Please ensure that any relevant individuals
    are made aware of this Privacy Notice and the individual rights and information it sets out,
    prior to providing their information to us or our obtaining their information from another
    source. If you, or anyone else on your behalf, has provided or provides information on an
    individual connected to your business to us (or any member of the group), you or they must first
    ensure that you or they have the authority and appropriate legal basis to do so. 2. INFORMATION
    WE COLLECT 2.1 This Privacy Notice is concerned with personal information (also called personal
    data) we collect about you. Personal data means any data by which you as an individual can be
    directly or indirectly (e.g. if several pieces of data are combined) be identified. Data which
    is completely anonymised or de-personalised will not count as personal data. 2.2 Some of the
    personal data we hold about you will have been supplied by yourself. Other personal information
    may come from your financial advisor, solicitors, broker, employer or other intermediary, other
    members of the group, or other sources you've asked us to obtain information from. We might also
    get some of it from publicly available sources. 2.3 We will usually collect personal information
    such as: 2.3.1 personal details (e.g. name, previous names, gender, date and place of birth,
    occupation and/or source of wealth); 2.3.2 identification materials we may need for our
    compliance obligations (e.g. a copy of your passport or national identity card, national
    insurance number, utility bills, financial details etc.); 2.3.3 contact details (e.g. address,
    email address, position in company, landline and mobile numbers); 2.3.4 other information about
    you which you may have provided us with during the course of our relationship with you, e.g. by
    filling out forms or during face-to-face contact, telephone, email and the like; 2.3.5 financial
    information and information about your relationship with us, including your ways of interacting
    with us, your investments and interactions with Bite, transactions records, bank feeds, market
    trades, sort code and account numbers of relevant accounts or payments made by us into your
    account; 2.3.6 complaints or disputes you may have had with us or other members of the group and
    details of the underlying transaction (where applicable); 2.3.7 information about you which is a
    matter of public record or readily obtainable and which we deem relevant in relation to your
    dealings with Bite (media, court judgements, credit checks etc.); 2.3.8 sales and marketing
    information (e.g. offers you have received from us and how you reacted to them); 2.3.9 records
    of correspondence and other communications between you and your representatives and Bite ,
    including email, telephone calls, letters and the like; 2.3.10 information that we need to
    support our regulatory obligations (e.g. information about transaction details, detection of any
    suspicious and unusual activity and information about parties connected to you or these
    activities); 2.3.11 information from third party providers who assist us to combat fraud, money
    laundering and other crimes; and 2.3.12 information you asked and authorised us to collect for
    or about you, such as information about your accounts from your bankers. 2.4 In addition to the
    above in relation to the Website we may also collect: 2.4.1 information that you provide to us,
    such as when you fill out a contact or web form, or if you register to receive alerts or
    updates; 2.4.2 information that we obtain or learn, such as information about the browser or
    device you use to access this site, how you use this site and the pages you visit, traffic and
    location data; 2.4.3 information you provide to us if you experience problems when using the
    Website. We may also ask you to complete surveys for research purposes, although you don't have
    to respond to these; and 2.4.4 if we have an existing relationship with you, and we are able to
    identify you from information obtained or provided by your use of the site, we may associate
    those sets of information, for example to enable us to respond to a query you have submitted.
    2.5 Please note that the Website is not designed or intended for children and we do not
    knowingly collect data relating to children. 2.6 We may also collect certain types of sensitive
    or special category data about you, such as details about any criminal records or information
    about your health, political affiliations, ethnicity or religious beliefs. 3. HOW WE WILL USE
    PERSONAL DATA 3.1 We will only use your personal information when the Law allows us to. Most
    commonly, we will use your personal information in the following circumstances: 3.1.1 Where we
    need to perform the contract we have entered into with you. 3.1.2 Where we need to comply with a
    legal or regulatory obligation. 3.1.3 Where it is necessary for legitimate interests pursued by
    us or a third party and your interests and fundamental rights do not override those interests.
    3.1.4 Where we need to protect your interests (or someone else's interests). 3.1.5 Where it is
    needed in the public interest or for official purposes (such as compliance with a court order or
    regulatory direction). 3.2 Based on the reasons for using your data noted in 3.1 above, the
    purposes for which we use your information as an investor commonly include: 3.2.1 to carry out
    your instructions as an investor via Bite Products(contractual performance, legitimate
    interests); 3.2.2 to make offers and promotions to you (legitimate interests); 3.2.3 to pay out
    dividends or make other payments to you (contractual performance, legal obligation); 3.2.4 to
    communicate with you regarding your investments and relationship with Bite Products (contractual
    performance, legitimate interests); 3.2.5 to undertake data analytics to better understand our
    users' motivations and strategies and as a result improve or adjust strategy and performance of
    Bite (legitimate interests); 3.2.6 to protect our legal rights and complying with our legal
    obligations, including combatting financial crime (legal compliance, public interest); 3.2.7 to
    conduct market research (legitimate interest); 3.2.8 to conduct risk management (legitimate
    interest, public interest, legal obligation); 3.2.9 to verify your identity and/or whether you
    are a politically exposed person in accordance with our legal obligations to undertake screening
    (legal obligation); 3.3 Based on the reasons for using your data noted in 3.1 above, the
    purposes for which we use your information as an employer, officer or contractor commonly
    include: (a) paying you, providing with benefits which may include pension, private health
    insurance, life insurance or permanent health insurance and administering the contract we have
    entered into with you (contractual performance); (b) deducting tax and social security
    contributions (legal obligation); (c) determining the terms on which you are appointed and about
    your continued engagement (legitimate interests); and (d) Updating you as to relevant board
    meetings and providing you with information about the performance of Bite's products (legitimate
    interests; legal obligation). 3.4 In addition in relation to the Website we may use information
    provided or obtained to: 3.4.1 respond to your queries and reports (for example, if you've asked
    a question or submitted a report via the Website); 3.4.2 provide you with information, products
    or services you have requested or which we think may interest you, unless you tell us otherwise;
    3.4.3 carry out our obligations from any contracts entered into between you and us; 3.4.4 allow
    you to participate in any interactive features of the Website; 3.4.5 notify you about changes to
    the Website; 3.4.6 provide you with alerts or updates where you've consented to receive these by
    registering on the Website. You can opt out of these at any time by clicking the “unsubscribe”
    link at the bottom of each email we send you; 3.4.7 ensure site content is presented in the most
    effective manner for the device you're accessing it from (e.g. your mobile phone, personal
    computer or other electronic device); and 3.4.8 detect and prevent misuse or abuse of the
    Website or our services. 4. INFORMATION ABOUT THE WEBSITE 4.1 IP addresses We may collect
    information about your computer (or mobile device), including where available your IP address,
    operating system and browser type, for system administration or for our own commercial purposes.
    This is statistical data about our users' browsing actions and patterns, and does not identify
    any individual. 4.2 Cookies We use cookies and similar technologies to distinguish you from
    other users of this site, to improve your experience when accessing this site, and to improve
    the site. Detailed information on the cookies we use and the purposes for which we use them are
    set out in our Cookie Policy at [www.biteinvestments.com.] 5. WHO WE MIGHT SHARE YOUR PERSONAL
    DATA WITH 5.1 We may share relevant personal information of yours with other parties where it is
    lawful to do so, including where: 5.1.1 it is necessary to comply with our contractual
    obligations or with your instructions; 5.1.2 we have a public or legal duty to do so (e.g. to
    assist with detecting and preventing fraud, tax evasion and financial crime or compliance with a
    court order); 5.1.3 we are obligated to in connection with regulatory reporting, litigation or
    asserting or defending legal rights and interests; 5.1.4 Bite has a legitimate business reason
    for doing so (eg to manage risk, verify identity, enable another business to provide you with
    services you've requested, or assess your suitability for investing with a Bite Product ); 5.1.5
    where we work with third parties who perform AML, KYC or other regulatory-related outsourced
    services on behalf of or directly for Bite and it's products; 5.1.6 we have asked you if we can
    share it, and you gave consent; 5.2 Parties we might share your personal information with can
    include (without limitation): 5.2.1 fund managers, brokers, sponsors and market makers,
    registrars, listing agents; 5.2.2 any trustees, beneficiaries, administrators or executors;
    5.2.3 people who give guarantees or other security for any amounts you owe us; 5.2.4 banks you
    instruct us to make payments to and receive payments from; 5.2.5 third parties who manage the
    investments on our behalf, including investment managers, letting agents, stockbrokers; 5.2.6
    third parties who host the Website or provide services related to it, including IT security
    providers; 5.2.7 other financial institutions, lenders and holders of security over any property
    or assets, tax authorities, stock market authorities, trade associations, credit reference
    agencies, payment service providers and debt recovery agents; 5.2.8 any people or companies
    where required in connection with potential or actual corporate restructuring, merger,
    acquisition or takeover, including any transfer or potential transfer of any of our rights or
    duties under our agreement with you; 5.2.9 law enforcement, government, courts, dispute
    resolution bodies, our regulators, auditors and any party appointed or requested by our
    regulators to carry out investigations or audits of our activities; 5.2.10 other parties
    involved in any disputes, including disputed transactions; 5.2.11 fraud prevention agencies
    who'll also use personal data to detect and prevent fraud and other financial crime and to
    verify your identity; 5.2.12 anyone who provides instructions to us on your behalf (e.g. under a
    Power of Attorney, solicitors, intermediaries, investment managers etc.); 5.2.13 anybody else
    that you instructed us to share your information with by you; 5.2.14 insurers who may provide
    cover for your investments with us; 5.3 We might share aggregated and/or anonymised or
    de-personalised data with third parties for analytics, marketing and research purposes. Where we
    do so, we will ensure that neither you nor any other person will be identifiable from the data.
    6. RETENTION OF PERSONAL DATA 6.1 Bite keeps personal data only for as long as it is necessary
    for the specific purpose the data was collected for or as long as we are required by applicable
    laws and regulation. Bite is generally required to retain its records for at least five (5)
    years from the date the relationship with an investor ends or potentially longer, depending on
    the kind of data and relevant laws and regulations applicable to it. 6.2 We may keep personal
    data likewise for longer periods where we have a legitimate interest for doing so, for instance
    to address complaints, assert or defend our rights in litigation or other dispute resolution
    procedures or to respond to requests from regulators or assist judicial authorities. 6.3 Any
    information we are not required to hold for any minimum period and for which there is no purpose
    in us holding it anymore will be deleted, destroyed or returned to you more promptly. 6.4 Where
    Bite shared your personal data with third parties, the privacy notices and laws and regulations
    of the third party will determine how long they will have to retain your data. 7. INTERNATIONAL
    TRANSFERS OF PERSONAL DATA 7.1 Where we have to transfer personal data outside of the Cayman
    Islands we will ensure that the transfer is lawful and that the data is appropriately secure and
    protected. Where necessary, we will ensure that separate and appropriate legal agreements are
    put in place. 7.2 Reasons for having to transfer your personal data outside the Cayman Islands
    may include: 7.2.1 we need to carry out our contract with you; 7.2.2 we have to fulfil a legal
    obligation; 7.2.3 we need to protect the public interest; and / or 7.2.4 for your or our
    legitimate interests. 7.3 In some countries the law might compel Bite to share certain
    information (e.g. with tax authorities). We will only share any information with parties who
    have the lawful authority and right to see it and only to the extent that such parties are
    permitted to see it. 8. INDIVIDUALS' RIGHTS 8.1 As an individual or “data subject”, you have
    certain rights in relation to your personal data. These rights include: 8.1.1 the right to
    access information we hold about you and to obtain information about how we process it; 8.1.2
    the right to object to and withdraw your consent to Bite's processing of your information. This
    right can be exercised at any time. However, Bite may continue to process your personal
    information if there is another legitimate reason or legal obligation for doing so. Please also
    note that depending on which kind of processing you object to, Bite may no longer be able to
    perform its contractual obligations with you； 8.1.3 in some circumstances, you have the right
    to receive certain information you have provided to us in an electronic format and / or request
    that we transmit it to a third party; 8.1.4 the right to request that we rectify information we
    hold about you if it is inaccurate or incomplete; 8.1.5 in some circumstances, you have the
    right to request that erasure and deletion of personal data we hold. We may however continue to
    retain it if we are entitled or required by law to do so; 8.1.6 the right to object to, and to
    request that we restrict, our processing of your information in some circumstances. Please note
    that despite this general right we may be entitled under law to continue processing the
    information and / or to refuse that request. 8.2 You also have the right to complain to the data
    protection regulator in the Cayman Islands, which is the Office of the Ombudsman. You can access
    their website here: ombudsman.ky 8.3 You may also be able to seek redress for any violation of
    your data protection rights in the Cayman Islands courts or challenge a decision by the
    regulator. 9. MISCELLANEOUS 9.1 Please ensure that any data you give us or ask third parties to
    provide to us is up to date, accurate and complete in all respects. Please inform us about any
    changes as soon as reasonably possible. 9.2 We use a range of measures to keep information safe
    and secure which may include encryption and other forms of security. We require our staff and
    any third parties who carry out any work on Bite's behalf to comply with appropriate compliance
    standards including obligations to protect any information and applying appropriate measures for
    the use and transfer of information. If you wish to know more about our data protection
    measures, please contact us (details provided below). 9.3 This Privacy Notice is governed by the
    laws of the Cayman Islands. Any dispute arising from or in connection with this Privacy Notice
    is subject to the exclusive jurisdiction of the Cayman Islands courts. 10. CONTACT US For any
    further questions or queries in relation to this Privacy Notice, please get in touch with your
    usual contact or: Write to: Data protection Officer, c/o Bite Asset Management (Cayman) Limited
    64 Shedden Road, George Town, PO Box 31325 SMB, Grand Cayman, KY1-1206, Cayman Islands
    dataprotection&#64;biteinvestments.com
  </p>
</div>
