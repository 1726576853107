<div class="flex h-full w-full items-center justify-center">
  <div class="flex flex-col items-center justify-center">
    <div class="img-box flex justify-center">
      <img src="./assets/images/without-permission.png" class="h-40" alt="" />
    </div>
    <div class="title-box mt-9 text-center font-semibold">
      <div>Sorry, Bite Stream is temporarily closed for</div>
      <div>external registration.</div>
    </div>
    <div class="subTitle-box mt-4 flex flex-col items-center justify-center">
      <div>Get in touch with us at salessupport&#64;biteinvestments.com to learn more</div>
      <div>about Bite Stream's Pro, Teams and Enterprise packages to see for</div>
      <div>yourself the difference our platform can make to streamlining your private</div>
      <div>investment workflows.</div>
    </div>
  </div>
</div>
